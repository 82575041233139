import { useEffect } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";

const RootPage = () => {
  const navigate = useNavigationModule();

  useEffect(() => {
    navigate.push("note", {});
  }, [navigate.push]);

  return <AnimatedPageTransition />;
};

export default RootPage;
