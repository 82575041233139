import Note from "./presentation/pages/note";
import Top from "./presentation/pages/top";
import AreasPage from "./presentation/pages/areas";
import ToursPage from "./presentation/pages/tours";
import GuidesPage from "./presentation/pages/guides";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import RootPage from "./presentation/pages/root";
import { AnimatePresence } from "framer-motion";
import MapPage from "./presentation/pages/map";
import { NavigationModuleProvider } from "@agp/shared.user-app/application/context/navigation";
import { PagePaths } from "@agp/shared.user-app/application/context/navigation/type";
import { NavigationCoreModuleProvider } from "./domain/context/navigation-core";
import { useNavigationCoreModule } from "./domain/module/navigation-core";
import { useQueryModule } from "./domain/module/query";
import { useNavigationModule } from "./domain/module/navigation";
import { applicationConfig } from "./infrastructure/application-config";

const Router = () => {
  const location = useLocation();

  // Create Modules
  const navigationCoreModule = useNavigationCoreModule();
  const navigationModule = useNavigationModule(
    applicationConfig,
    navigationCoreModule
  );
  useQueryModule();

  return (
    <NavigationCoreModuleProvider module={navigationCoreModule}>
      <NavigationModuleProvider module={navigationModule}>
        <AnimatePresence mode="wait" initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route path={PagePaths.note} element={<Note />} />
            <Route path={PagePaths.top} element={<Top />} />
            <Route path={PagePaths.areas} element={<AreasPage />} />
            <Route path={PagePaths.tours} element={<ToursPage />} />
            <Route path={PagePaths.guides} element={<GuidesPage />} />
            <Route path={PagePaths.map} element={<MapPage />} />
            <Route path={PagePaths.root} element={<RootPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </AnimatePresence>
      </NavigationModuleProvider>
    </NavigationCoreModuleProvider>
  );
};

const NotFoundPage = () => <div>Not Found</div>;

export default () => (
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);
