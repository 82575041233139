import { Icon, Image } from "semantic-ui-react";
import styled from "styled-components";
import PlayingAnimation from "../guides/playing-animation";
import { Divider } from "../../components/divider";
import { Guide } from "@agp/shared.user-app/entity";
import ServiceLocator from "../../../domain/service-locator";

const GuideItem = (props: {
  onClickGuide: (id: string) => void;
  guide: Guide;
  active: boolean;
  isListened: boolean;
}) => {
  const isPlaying = ServiceLocator.GuidePlayerModule.isPlaying.watch();
  return (
    <>
      <Wrapper onClick={() => props.onClickGuide(props.guide.id)}>
        <RecommendWrapper>
          {props.guide.isRecommend && (
            <Icon name="star" size="small" color="grey" />
          )}
        </RecommendWrapper>
        <Contents>
          <ThumbnailWrapper>
            <Image src={props.guide.thumbnailUrls[0] || "/no-image.png"} />
          </ThumbnailWrapper>
          <GuideInfoWrapper>
            <NameWrapper>
              {props.active && <PlayingAnimation active={isPlaying} />}
              <Name isBold={!props.isListened}>
                {props.guide.trackNumber}.{props.guide.name}
              </Name>
            </NameWrapper>
            <DescriptionWrapper>{props.guide.description}</DescriptionWrapper>
          </GuideInfoWrapper>
        </Contents>
      </Wrapper>
      <Divider />
    </>
  );
};

export default GuideItem;

const itemMargin = "10px";

const Wrapper = styled.div`
  margin: ${itemMargin} 0;
  cursor: pointer;
`;

const Contents = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 0 !important;
  height: 65px;
`;

const RecommendWrapper = styled.div`
  width: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ThumbnailWrapper = styled.div`
  width: 65px;
  min-width: 65px;
  border-radius: 5px;
  overflow: hidden;
`;

const GuideInfoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  height: 100%;
  gap: 5px;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.color.primaryText};
`;

const Name = styled.div<{ isBold: boolean }>`
  font-weight: ${(props) => (props.isBold ? 500 : 200)};
  font-size: 1.5rem;
`;

const DescriptionWrapper = styled.div`
  font-size: 1.15rem;
  flex-shrink: 1;
  color: ${(props) => props.theme.color.secondaryText};
  overflow: hidden;
`;
