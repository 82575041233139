import styled from "styled-components";
import { Button } from "../../../components/button";
import { useTopModel } from "../model";

export const TopAcoustiguide = () => {
  const model = useTopModel();
  return (
    <Body bgImageUrl={"/minpaku-demo/bg.png"}>
      <Content>
        <Section>
          <Title>
            <div>みんぱく</div>
            <div>音声ガイド</div>
          </Title>
          <StartButton onClick={model.onClickStartButton}>スタート</StartButton>
        </Section>
        <Section>
          <Logo src={"/minpaku-demo/minpaku_logo_b.png"} />
        </Section>
      </Content>
    </Body>
  );
};

const Body = styled.div<{ bgImageUrl: string }>`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${window?.innerHeight + "px" ?? "100vh"};
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 60%,
      rgb(255, 255, 255, 0) 65%
    ),
    url(${(props) => props.bgImageUrl});
  background-size: cover;
  background-position: center;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 42vh;
  bottom: 0;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const Title = styled.div`
  margin-bottom: 15px;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4.4rem;
  line-height: 5rem;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StartButton = styled(Button)`
  color: black;
  width: 50%;
  height: 50px;
  font-size: 2.5rem;
  background-color: white;
`;

const Logo = styled.img`
  width: 90%;
`;
