import { Guide, Tour } from "@agp/shared.user-app/entity";
import { useCallback, useEffect, useMemo, useState } from "react";
import GuideItem from "../../../containers/guide-item";
import { AnimatePresence, motion, Variants } from "framer-motion";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { headerHeight } from "../../../components/layout";

export type DetailedTour = Tour & {
  guides: Guide[];
};

interface ExpandableTourCardProps {
  labelColor?: string;
  tour: DetailedTour;
  onClickGuide: (id: string) => void;
  currentPlayingGuideId?: string;
}

const listVariants: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  hidden: {
    transition: {
      duration: 0,
    },
  },
};

const itemVariants: Variants = {
  visible: {
    transition: {
      duration: 0.3,
    },
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const ExpandableTourCard = (props: ExpandableTourCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const handleClickExpandButton = useCallback(() => {
    setIsExpanded((x) => !x);
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  const containerId = useMemo(
    () => `container-${props.tour.id}}`,
    [props.tour]
  );

  const hashGuideId = useMemo(() => {
    const id = location.hash.replace("#", "");
    return props.tour.guides.map((x) => x.id).includes(id) ? id : null;
  }, [props.tour.guides, location.hash]);

  useEffect(() => {
    setIsScrolled(false);
    if (hashGuideId) setIsExpanded(true);
  }, [hashGuideId]);

  useEffect(() => {
    if (!hashGuideId || !isExpanded || isScrolled) return;

    const elem = document.getElementById(`guide-item-${hashGuideId}`);
    elem?.scrollIntoView({ behavior: "smooth" });

    setIsScrolled(true);
  }, [hashGuideId, containerId, props.tour.guides, isExpanded, isScrolled]);

  return (
    <>
      <Body bgImage={props.tour.thumbnailUrl} onClick={handleClickExpandButton}>
        <Content bgColor={props.labelColor}>
          <Title>{props.tour.name}</Title>
          <Description>{props.tour.description}</Description>
        </Content>
        <ExpandButton>{isExpanded ? "－" : "＋"}</ExpandButton>
      </Body>

      {isExpanded && (
        <AnimatePresence>
          <Guides
            variants={listVariants}
            initial={"hidden"}
            animate={"visible"}
          >
            {props.tour.guides.map((x, i) => (
              <GuideItemWrapper
                key={`guide-item-${i}`}
                id={`guide-item-${x.id}`}
                variants={itemVariants}
              >
                <GuideItem
                  onClickGuide={props.onClickGuide}
                  guide={x}
                  active={props.currentPlayingGuideId === x.id}
                  isListened={false}
                />
              </GuideItemWrapper>
            ))}
          </Guides>
        </AnimatePresence>
      )}
    </>
  );
};

const Body = styled.div<{ bgImage: string }>`
  position: relative;
  width: 100%;
  aspect-ratio: 2;
  overflow: hidden;
  background: url(${(props) => props.bgImage});
  background-size: cover;
`;

const ExpandButton = styled.button`
  position: absolute;
  bottom: 10%;
  right: 15px;
  border: black 1px solid;
  border-radius: 5px;
  height: 20%;
  aspect-ratio: 1;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const Content = styled.div<{ bgColor?: string }>`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 40%;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgba(180, 200, 240, 0.8)"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

const Title = styled.div`
  font-size: 2rem;
`;

const Description = styled.div``;

const Guides = styled(motion.div)`
  padding-top: 5px;
  width: 90%;
  margin: auto;
`;

const GuideItemWrapper = styled(motion.li)`
  scroll-margin: ${headerHeight}px;

  // TODO: 本来必要ないが、これをつけないと Header の Backdrop-Filter がバグる。要調査
  background-color: rgb(240, 240, 240);
`;
