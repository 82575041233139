import Layout from "../../components/layout";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import { useMemo } from "react";
import { TTemplate } from "../../../domain/entities/template";
import ServiceLocator from "../../../domain/service-locator";
import { ToursAcoustiguide } from "./acoustiguide";
import { ToursGeneral } from "./general";

const ToursPage = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const application = localizedEntityRepository.entities.watch().application;

  const template = useMemo<TTemplate>(
    () =>
      application?.id === "08dcc024-e8ac-4fc3-81bd-b80fd82d7f83"
        ? "Acoustiguide"
        : "General",
    [application]
  );

  return (
    <Layout
      headerLeftComponents={template === "Acoustiguide" ? ["menu"] : ["back"]}
      headerRightComponents={
        template === "Acoustiguide" ? ["map", "keypad"] : []
      }
      backTransitionOption={{
        pageInType: "fade",
        pageOutType: "fade",
      }}
    >
      <AnimatedPageTransition>
        {template === "Acoustiguide" ? <ToursAcoustiguide /> : <ToursGeneral />}
      </AnimatedPageTransition>
    </Layout>
  );
};

export default ToursPage;
