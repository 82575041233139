import { DetailedTour, ExpandableTourCard } from "./expandable-tour-card";
import styled from "styled-components";
import { useCallback, useMemo } from "react";
import ServiceLocator from "../../../../domain/service-locator";

export const ToursAcoustiguide = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const entities = localizedEntityRepository.entities.watch();

  const currentAreaId =
    ServiceLocator.ApplicationStateRepository.currentAreaId.watch();
  const currentTrack = guidePlayerModule.currentTrack.watch();

  const currentTours = useMemo<DetailedTour[]>(
    () =>
      !currentAreaId
        ? []
        : entities.tours
            .filter((x) => x.areaId === currentAreaId)
            .map((x) => ({
              ...x,
              guides: entities.guides.filter((y) => y.tourId === x.id),
            })),
    [currentAreaId, entities]
  );

  const handleClickGuide = useCallback(
    (id: string) => {
      const shouldShowPlayer = guidePlayerModule.currentTrack.value === null;
      guidePlayerModule.select(id);
      guidePlayerModule.play();
      if (shouldShowPlayer) playerLayoutModule.playerType.setValue("full");
    },
    [guidePlayerModule, playerLayoutModule.playerType]
  );

  return (
    <Body>
      {currentTours.map((x, i) => (
        <CardWrapper key={`tour-card-${i}`}>
          <ExpandableTourCard
            tour={x}
            onClickGuide={handleClickGuide}
            labelColor={
              i === 0
                ? "rgba(180, 200, 240, 0.8)"
                : i === 1
                ? "rgba(240, 200, 180, 0.8)"
                : "rgba(200, 240, 180, 0.8)"
            }
            currentPlayingGuideId={currentTrack?.id}
          />
        </CardWrapper>
      ))}
    </Body>
  );
};

const Body = styled.div`
  margin: 0 5px;
  padding-bottom: 30px;
`;

const CardWrapper = styled.div`
  margin: 10px 0;
`;
