import NoteContainer from "../../containers/note";
import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";

const NotePage = () => {
  return (
    <AnimatedPageTransition>
      <NoteContainer />
    </AnimatedPageTransition>
  );
};

export default NotePage;
