import ToursCardList from "./tours-card-list";
import ToursList from "./tours-list";
import ServiceLocator from "../../../../domain/service-locator";

export const ToursGeneral = () => {
  const { applicationConfig } = ServiceLocator.MasterDataModule.masterData;
  return applicationConfig?.toursPageLayoutType === "Card" ? (
    <ToursCardList />
  ) : (
    <ToursList />
  );
};
