export type NavigationType = "push" | "back" | "change";

export interface NavigationOption {
  pageOutType?: PageTransitionType;
  pageInType?: PageTransitionType;
}

export type PageTransitionType = "none" | "fade" | "slideRight" | "slideLeft";

export type PageType = "note" | "top" | "areas" | "tours" | "guides" | "map";

export const PagePaths: { [key: string]: string } = {
  root: "/",
  note: "/note",
  top: "/top",
  areas: "/areas",
  tours: "/tours",
  guides: "/guides",
  map: "/map",
};
