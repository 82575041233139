import styled from "styled-components";
import { useCallback, useState } from "react";
import { BackIcon } from "./back-icon";
import ServiceLocator from "../../../../domain/service-locator";

const numbers = [...Array(10)].map((_, i) => (i + 1) % 10);

interface KeypadProps {
  onComplete: () => void;
}

export const Keypad = (props: KeypadProps) => {
  const theme = ServiceLocator.ThemeStore.theme;
  // const [_, setSearchParams] = useSearchParams();
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const languageRepository = ServiceLocator.LanguageRepository;

  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const { application, tours, guides } =
    localizedEntityRepository.entities.watch();
  const currentLanguageId = languageRepository.currentLanguageId.watch();
  const languages = languageRepository.languages;

  const [num, setNum] = useState<string>("");

  const handleClickOkButton = useCallback(() => {
    const guide = guides.find((x) => x.trackNumber.toString() === num);
    if (!guide) return;

    const currentLanguage = languages.find((x) => x.id === currentLanguageId);
    if (!application || !currentLanguage) return;

    const tour = tours.find((x) => x.id === guide.tourId);
    if (!tour) return;

    window.location.hash = guide.id;

    const shouldShowPlayer = guidePlayerModule.currentTrack.value === null;
    guidePlayerModule.select(guide.id);
    if (shouldShowPlayer) playerLayoutModule.playerType.setValue("full");
    // setSearchParams((prev) => {
    //   prev.set("guideId", guide.id);
    //   return prev;
    // });
    props.onComplete();
  }, [
    guides,
    languages,
    application,
    tours,
    guidePlayerModule,
    playerLayoutModule.playerType,
    props,
    num,
    currentLanguageId,
  ]);

  return (
    <Body>
      <Description color={theme.color.primary}>
        聴きたいガイド番号を押して
        <br />
        OKボタンを押してください。
      </Description>
      <PadWrapper>
        <Preview>
          {num.split("").map((x) => (
            <PreviewText>{x}</PreviewText>
          ))}
          <DeleteButton
            onClick={() => {
              setNum((x) => (x.length > 0 ? x.slice(0, x.length - 1) : x));
            }}
          >
            <BackIcon color={theme.color.primary} width={45} />
          </DeleteButton>
        </Preview>
        <Pad>
          {numbers.map((x) => (
            <NumberWrapper
              key={`keypad-num-${x}`}
              onClick={() =>
                setNum((y) => (y.length >= 3 ? y : y + x.toString()))
              }
            >
              <Number color={theme.color.primary}>{x}</Number>
            </NumberWrapper>
          ))}
        </Pad>
      </PadWrapper>
      <OKButton onClick={handleClickOkButton} color={theme.color.primary}>
        OK
      </OKButton>
    </Body>
  );
};

const Body = styled.div`
  width: 100%;
`;

const Description = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  color: white;
  font-size: 2rem;
  text-align: center;
`;

const PadWrapper = styled.div`
  width: 60%;
  margin: auto;
`;

const Preview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
`;

const PreviewText = styled.div`
  font-size: 3rem;
  text-align: center;
  width: 3rem;
`;

const DeleteButton = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Pad = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const NumberWrapper = styled.div`
  width: calc(100% / 3);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Number = styled.div<{ color: string }>`
  width: 85%;
  aspect-ratio: 1;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  color: white;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OKButton = styled.div<{ color: string }>`
  margin-top: 20px;
  cursor: pointer;
  background-color: ${(props) => props.color};
  color: white;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 2rem;
`;
