import React from "react";
import ReactDOM from "react-dom/client";
import { Boot } from "./boot";
import "semantic-ui-css/semantic.min.css";
import "./reset.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Boot />
  </React.StrictMode>
);
